export * from './analysis.utils'
export * from './datetime.utils'
export * from './stoppage.utils'
export * from './shift.utils'
export * from './formData.utils'
export * from './plant.utils'
export * from './eventEdit'
export * from './attachments.utils'
export * from './test.utils'
export * from './string.utils'
export * from './materials.utils'
export * from './featureFlag.utils'
export * from './roundTo15MinIntervalStart.utils'
export * from './roundTo15MinIntervalEnd.utils'
export * from './productionVolumes.utils'
export * from './numberFormat.helpers'
export * from './score.utils'
export * from './kpi.utils'
export * from './kms.utils'

export const getBrowserLanguage = (): string => {
  return navigator.language?.split('-')[0] ?? 'en'
}
